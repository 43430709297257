import { React, useContext } from 'react';
import styles from '../styles/Home.module.css';  // CSSファイルをインポート
import Facilities from '../components/Facilities';
import Faq from '../components/Faq';
import TopImage from '../components/TopImage';

import StaySteps from '../components/StaySteps';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';



function Home() {


    return (
        <>
            <Navbar />
            <div className={styles.home}>
                <TopImage />
                <main>
                    <div className={styles.explain_brief}>
                        <div className={styles.container}>
                            <div className="row align-items-start">
                                <div className="col-12 col-md-6"> {/* Bootstrapのグリッドシステムを使用 */}
                                    <div className={styles.image}>
                                        <img className={styles.image_only} src="/images/homephoto.jpg" alt="Home" />
                                    </div>
                                </div>
                                <div className="col-11 col-md-6 m-auto"> {/* col-12は小さい画面で全幅、md以上の画面では6列 */}
                                    <div className={styles.text}>
                                        <p>
                                            ゲストハウス&nbsp;巴.comは2018年に1号棟を、翌年にはOnepiece Houseをオープンしました。<br />
                                            現在は、6施設を運営、1施設の管理を請け負っており、それぞれの施設には独自のコンセプトがあります。<br />
                                            シンプルで落ち着いた雰囲気のものから、フィギュアや装飾品を展示したテーマ性の高い施設まで、快適さと個性を兼ね備えたデザインとなっており、ゲストの皆様に特別な体験を提供しています。<br />
                                            今では、国内外問わず多くの方にご利用いただいております。<br />
                                            巴.comは、函館を訪れるすべてのゲストや地域の皆様にとって、忘れられない滞在となるよう、日々サービスの向上に努めています。
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Facilitiesコンポーネントを表示 */}
                    <Facilities />
                    <StaySteps />
                    <Faq />

                    {/* <Contact /> */}



                </main>
            </div>
            <Footer />
        </>
    );
}

export default Home;
