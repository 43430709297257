import React from 'react';
import styles from '../styles/PrivacyPolicy.module.css';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

function PrivacyPolicy() {
    return (
	<>
	<Navbar />
        <div className={styles.container}>
            <div className={styles.wrapper}>

                <h1>プライバシーポリシー</h1>
                <p>最終更新日: 2023年10月1日</p>

                <h2>1. 情報の収集</h2>
                <p>
                    当サイトでは、ユーザーから提供された情報（名前、メールアドレスなど）を収集します。
                    また、サイトの利用状況に関する情報（IPアドレス、ブラウザの種類など）も自動的に収集します。
                </p>

                <h2>2. 情報の利用</h2>
                <p>
                    収集した情報は、以下の目的で使用されます：
                    <ul>
                        <li>サービスの提供と改善</li>
                        <li>ユーザーサポートの提供</li>
                        <li>マーケティングおよびプロモーション</li>
                    </ul>
                </p>

                <h2>3. 情報の共有</h2>
                <p>
                    当サイトは、ユーザーの同意なしに個人情報を第三者と共有することはありません。
                    ただし、法的義務がある場合や、サービス提供のために必要な場合は除きます。
                </p>

                <h2>4. クッキーについて</h2>
                <p>
                    当サイトでは、クッキーを使用してユーザーの体験を向上させています。
                    クッキーは、ユーザーのブラウザに保存される小さなデータファイルです。
                </p>

                <h2>5. セキュリティ</h2>
                <p>
                    当サイトは、ユーザーの個人情報を保護するために適切なセキュリティ対策を講じています。
                    ただし、インターネット上の情報の完全な安全性を保証することはできません。
                </p>

                <h2>6. プライバシーポリシーの変更</h2>
                <p>
                    当サイトは、プライバシーポリシーを随時変更する権利を保留します。
                    変更があった場合は、当ページにてお知らせいたします。
                </p>

                <h2>7. お問い合わせ</h2>
                <p>
                    プライバシーポリシーに関する質問や懸念がある場合は、以下の連絡先までご連絡ください：
                    <br />
                    Email: example@example.com
                </p>
            </div>
        </div>
	<Footer />
	</>
    );
}

export default PrivacyPolicy; 
